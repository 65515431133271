
import React from 'react'
import { FormattedMessage, FormattedHTMLMessage, Link } from "gatsby-plugin-intl"

const CrossroadsA = () => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-24">
            <div className="bg-beige border-2 border-beige-500 rounded-4xl p-8 lg:p-16">
                <h4 className="typo-lg mb-4"><FormattedMessage id="junction.workshops.title" /></h4>
                <p className="typo-text-lg mb-8"><FormattedMessage id="junction.workshops.text" /></p>
                <Link to="/workshops" className="btn btn-black"><FormattedMessage id="junction.workshops.button" /></Link>
            </div>
            <div className="bg-beige border-2 border-beige-500 rounded-4xl p-8 lg:p-16">
                <h4 className="typo-lg mb-4"><FormattedMessage id="junction.shop.title" /></h4>
                <p className="typo-text-lg mb-8"><FormattedMessage id="junction.shop.text" /></p>
                <div className="btn btn-black"><FormattedHTMLMessage id="junction.shop.button" /></div>
            </div>
        </div>
    )
}

export default CrossroadsA



