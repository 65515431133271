import React from "react"
import { useIntl, FormattedMessage, Link } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from '../components/layout'
import CrossroadsB from '../components/crossroads-b'
import SEO from "../components/seo"


if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
}

export default props => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO
                title={intl.formatMessage({ id: "site.menu.events" })}
            />
            <div className="header-bg bg-img-06">
                <div className="tip-container">
                    <div className="tip"><FormattedMessage id="site.clickHold" /></div>
                </div>
            </div>
            <div className="container mx-auto py-16 md:py-24 px-8">
                <div className="mb-16">
                    <p className="typo-xxl mb-4 md:mb-8"><FormattedMessage id="events.title" /></p>
                    <div className="xl:w-3/4">
                        <p className="typo-text-xxl mb-8">
                            <FormattedMessage id="events.text" />
                        </p>
                    </div>
                </div>
                <div className="">
                    <div className="xl:w-3/4 grid grid-cols-1 text-left sm:grid-cols-2 lg:grid-cols-3 gap-8 xl:gap-12">
                        <div className="mb-10 sm:mb-0">
                            <h3 className="typo-subtitle mb-2"><FormattedMessage id="events.events" /></h3>
                            <ul className="typo-text-xl">
                                <li className="border-b-2 border-t-2 border-mt py-2"><Link to="/events/#tea" className="link-no-ul"><FormattedMessage id="events.events.tea" /></Link></li>
                                <li className="border-b-2 border-mt py-2"><Link to="/events/#bespoke" className="link-no-ul"><FormattedMessage id="events.events.bespoke" /></Link></li>

                            </ul>
                        </div>

                    </div>
                </div>


            </div>

            <div id="tea" className="section-bg py-24">

                <div className="container mx-auto px-8">
                    <div className="xl:w-3/4">
                        <p className="typo-xl mb-4 md:mb-8"><FormattedMessage id="events.tea.title" /></p>
                        <p className="typo-text-lg"><FormattedMessage id="events.tea.text1" />
                        </p>
                    </div>
                </div>

                <div className="container mx-auto py-16 px-8">
                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 xl:gap-12">
                        <div><Img className="rounded-4xl" fluid={props.data.imgEventsTea4.childImageSharp.fluid} /></div>
                        <div><Img className="rounded-4xl" fluid={props.data.imgEventsTea2.childImageSharp.fluid} /></div>
                        <div><Img className="rounded-4xl" fluid={props.data.imgEventsTea3.childImageSharp.fluid} /></div>
                        <div><Img className="rounded-4xl" fluid={props.data.imgEventsTea1.childImageSharp.fluid} /></div>
                    </div>
                </div>

                <div className="container mx-auto pb-16 md:pb-24 px-8">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 xl:gap-12">
                        <div>
                            <h3 className="typo-subtitle"><FormattedMessage id="events.tea.footnote1.title" /></h3>
                            <p><FormattedMessage id="events.tea.footnote1.text" /></p>
                        </div>
                        <div>
                            <h3 className="typo-subtitle"><FormattedMessage id="events.tea.footnote2.title" /></h3>
                            <p><FormattedMessage id="events.tea.footnote2.text" /></p>
                        </div>
                        <div>
                            <h3 className="typo-subtitle"><FormattedMessage id="events.tea.footnote3.title" /></h3>
                            <p><FormattedMessage id="events.tea.footnote3.text" /></p>
                        </div>

                    </div>

                </div>

                <div className="container mx-auto px-8">
                    <div className="p-8 sm:p-16 bg-mt text-mt-white rounded-4xl w-full">
                        <h3 className="typo-lg mb-4"><FormattedMessage id="events.tea.booking.title" /></h3>
                        <p className="typo-text-lg xl:w-2/3 mb-8">
                            <FormattedMessage id="events.tea.booking.text" />
                        </p>
                        <a
                            href="https://forms.gle/BK2SVZPRz2jS14sv8"
                            className="btn btn-white"
                            target="_blank"
                            rel="noreferrer"
                            aria-label={intl.formatMessage({ id: "events.tea.booking.button" })}>
                            <FormattedMessage id="events.tea.booking.button" />
                        </a>
                    </div>

                </div>

            </div>

            <div className="relative">
                <div className="photo-caption"><FormattedMessage id="events.photoCaption1" /></div>
                <Img className="" fluid={props.data.image1.childImageSharp.fluid} />
            </div>

            <div id="bespoke" className="section-bg pt-24">

                <div className="container mx-auto pb-24 px-8">
                    <div className="xl:w-3/4">
                        <p className="typo-xl mb-4 md:mb-8"><FormattedMessage id="events.bespoke.title" /></p>
                        <p className="typo-text-lg mb-4 md:mb-8"><FormattedMessage id="events.bespoke.text1" /></p>
                        <a href="mailto:info@metsiktoit.ee" className="btn btn-green"><FormattedMessage id="site.button.email" /></a>

                    </div>
                </div>

                <div className="bg-yellow-200 text-yellow-900 font-semibold">
                    <div className="container sm:w-1/2 lg:w-1/3 text-center mx-auto typo-text-lg py-16">
                        <p className="text-center"><FormattedMessage id="events.bespoke.text2" /></p>
                    </div>
                </div>


                <div class="text-blue-900 bg-blue-50 py-24">
                    <div className="container mx-auto px-8">
                        <div className="md:w-3/4 xl:w-1/2 text-center mx-auto">
                            <p className="typo-lg mb-2"><FormattedMessage id="events.bespoke.tate.title" /></p>
                            <p className="typo-subtitle mb-5"><FormattedMessage id="events.bespoke.tate.meta" /></p>
                            <p className="typo-text mb-20"><FormattedMessage id="events.bespoke.tate.text" /></p>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
                            <div><Img className="rounded-4xl" fluid={props.data.imageTate3.childImageSharp.fluid} /></div>
                            <div><Img className="rounded-4xl" fluid={props.data.imageTate1.childImageSharp.fluid} /></div>
                            <div><Img className="rounded-4xl" fluid={props.data.imageTate2.childImageSharp.fluid} /></div>
                        </div>
                    </div>
                </div>

                <div class="text-red-900 bg-red-50 py-24">
                    <div className="container mx-auto px-8">
                        <div className="md:w-3/4 xl:w-1/2 text-center mx-auto">
                            <p className="typo-lg mb-2"><FormattedMessage id="events.bespoke.kai.title" /></p>
                            <p className="typo-subtitle mb-5"><FormattedMessage id="events.bespoke.kai.meta" /></p>
                            <p className="typo-text mb-20"><FormattedMessage id="events.bespoke.kai.text" /></p>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
                            <div><Img className="rounded-4xl" fluid={props.data.imageKai4.childImageSharp.fluid} /></div>
                            <div><Img className="rounded-4xl" fluid={props.data.imageKai1.childImageSharp.fluid} /></div>
                            <div><Img className="rounded-4xl" fluid={props.data.imageKai5.childImageSharp.fluid} /></div>
                        </div>
                    </div>
                </div>

                <div class="text-green-900 bg-green-50 py-24">
                    <div className="container mx-auto px-8">
                        <div className="md:w-3/4 xl:w-1/2 text-center mx-auto">
                            <p className="typo-lg mb-2"><FormattedMessage id="events.bespoke.welement.title" /></p>
                            <p className="typo-subtitle mb-5"><FormattedMessage id="events.bespoke.welement.meta" /></p>
                            <p className="typo-text mb-20"><FormattedMessage id="events.bespoke.welement.text" /></p>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
                            <div><Img className="rounded-4xl" fluid={props.data.imgWelement1.childImageSharp.fluid} /></div>
                            <div><Img className="rounded-4xl" fluid={props.data.imgWelement5.childImageSharp.fluid} /></div>
                            <div><Img className="rounded-4xl" fluid={props.data.imgWelement2.childImageSharp.fluid} /></div>

                            <div><Img className="rounded-4xl" fluid={props.data.imgWelement4.childImageSharp.fluid} /></div>
                            <div><Img className="rounded-4xl" fluid={props.data.imgWelement6.childImageSharp.fluid} /></div>

                            <div><Img className="rounded-4xl" fluid={props.data.imgWelement3.childImageSharp.fluid} /></div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="bg-img-04 w-full">
                <div className="container mx-auto py-16 md:py-24 px-8">
                    <CrossroadsB />
                </div>
            </div>

        </Layout>
    )
}


export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    imageBasicStripe: file(relativePath: { eq: "metsiktoit_workshops_basic_stripe.png" }) {
      ...fluidImage
    }
    imageKimchiStripe: file(relativePath: { eq: "metsiktoit_workshops_kimchi_stripe.png" }) {
      ...fluidImage
    }
    imageKombuchaStripe: file(relativePath: { eq: "metsiktoit_workshops_kombucha_stripe.png" }) {
      ...fluidImage
    }
    imageCustomStripe: file(relativePath: { eq: "metsiktoit_workshops_custom_stripe.png" }) {
        ...fluidImage
    }
    image1: file(relativePath: { eq: "metsiktoit_img_01.png" }) {
        ...fluidImage
    }

    imgEventsTea1: file(relativePath: { eq: "metsiktoit_events_tea_01.png" }) {
        ...fluidImage
    }
    imgEventsTea2: file(relativePath: { eq: "metsiktoit_events_tea_02.png" }) {
        ...fluidImage
    }
    imgEventsTea3: file(relativePath: { eq: "metsiktoit_events_tea_03.png" }) {
        ...fluidImage
    }
    imgEventsTea4: file(relativePath: { eq: "metsiktoit_events_tea_04.png" }) {
        ...fluidImage
    }
    imageTate1: file(relativePath: { eq: "metsiktoit_tate_01.png" }) {
        ...fluidImage
    }
    imageTate2: file(relativePath: { eq: "metsiktoit_tate_02.png" }) {
        ...fluidImage
    }
    imageTate3: file(relativePath: { eq: "metsiktoit_tate_03.png" }) {
        ...fluidImage
    }
    imageKai1: file(relativePath: { eq: "metsiktoit_kai_01.png" }) {
        ...fluidImage
    }
    imageKai2: file(relativePath: { eq: "metsiktoit_kai_02.png" }) {
        ...fluidImage
    }
    imageKai3: file(relativePath: { eq: "metsiktoit_kai_03.png" }) {
        ...fluidImage
    }
    imageKai4: file(relativePath: { eq: "metsiktoit_kai_04.png" }) {
        ...fluidImage
    }
    imageKai5: file(relativePath: { eq: "metsiktoit_kai_05.png" }) {
        ...fluidImage
    }
    imgWelement1: file(relativePath: { eq: "metsiktoit_welement_01.png" }) {
        ...fluidImage
    }
    imgWelement2: file(relativePath: { eq: "metsiktoit_welement_02.png" }) {
        ...fluidImage
    }
    imgWelement3: file(relativePath: { eq: "metsiktoit_welement_03.png" }) {
        ...fluidImage
    }
    imgWelement4: file(relativePath: { eq: "metsiktoit_welement_04.png" }) {
        ...fluidImage
    }
    imgWelement5: file(relativePath: { eq: "metsiktoit_welement_05.png" }) {
        ...fluidImage
    }
    imgWelement6: file(relativePath: { eq: "metsiktoit_welement_06.png" }) {
        ...fluidImage
    }
    imgWorkshopBasic01: file(relativePath: { eq: "metsiktoit_workshop_basic_01.png" }) {
        ...fluidImage
    }
    imgWorkshopBasic02: file(relativePath: { eq: "metsiktoit_workshop_basic_02.png" }) {
        ...fluidImage
    }
    imgWorkshopBasic03: file(relativePath: { eq: "metsiktoit_workshop_basic_03.png" }) {
        ...fluidImage
    }
    imgWorkshopBasic04: file(relativePath: { eq: "metsiktoit_workshop_basic_04.png" }) {
        ...fluidImage
    }
  }
`